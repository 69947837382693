import React, { Component } from 'react'

import { getClientUrl } from '../helpers/url'

import ImprintContent from '../content/ImprintContent'
import ClientLayout from '../layout/ClientLayout'

interface State {
    imprintText: string | null
}

export default class ImprintScreen extends Component {
    state = { imprintText: null } as State

    async componentDidMount() {
        const response = await fetch(getClientUrl('/imprint_content'))
        const { text } = await response.json()
        this.setState({ imprintText: text })
    }

    getImprintContent() {
        const { imprintText } = this.state
        if (imprintText === null) {
            return <ImprintContent></ImprintContent>
        }
        return <div dangerouslySetInnerHTML={{ __html: imprintText }}></div>
    }

    render() {
        const content = this.getImprintContent()
        return (
            <ClientLayout
                basicLayoutProps={
                    {
                        props: {
                            container: {
                                className: 'main-content-container lesson-article-container',
                            },
                        },
                    } as any
                }
                onReceiveData={() => {}}
            >
                <section className="section-article-content">{content}</section>
            </ClientLayout>
        )
    }
}
