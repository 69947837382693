import './index.css'

import React, { Component, RefObject } from 'react'

import { getLessonUrl } from '../../helpers/url'

interface Props {
    id: string
    onProgress?: (progress: number) => void
    hasNavbar?: boolean
    hasFooter?: boolean
}
interface State {
    height: number
}

export default class LessonViewPort extends Component<Props, State> {
    state = {
        html: null,
        height: 0,
    } as State

    private frame: RefObject<HTMLIFrameElement> = React.createRef()

    componentDidMount = () => {
        let navbarHeight = 64
        if (!this.props.hasNavbar) {
            navbarHeight = 0
        }
        let footerHeight = 64
        if (!this.props.hasFooter) {
            footerHeight = 0
        }
        window.addEventListener('resize', () => {
            this.setState({
                height: window.innerHeight - navbarHeight - footerHeight,
            })
        })
        this.setState({
            height: window.innerHeight - navbarHeight - footerHeight,
        })
    }

    render() {
        return (
            <div className="lesson-viewport-wrapper">
                <iframe
                    title="lesson-view-port-frame"
                    className="lesson-view-port"
                    style={{ height: this.state.height }}
                    ref={this.frame}
                    frameBorder="0"
                    onLoad={this.onLoaded}
                    src={getLessonUrl(this.props.id)}
                />
            </div>
        )
    }

    private onLoaded = async () => {
        // check frame ref;
        if (!this.frame.current || !this.frame.current.contentWindow) return

        const { id } = this.props

        const {
            contentWindow: { window },
        } = this.frame.current

        const reactApp = window.document.getElementById('app') as any

        const reactStore = reactApp._reactRootContainer._internalRoot.current.memoizedState.element.props.store

        const state = reactStore.getState()

        reactStore.subscribe(() => {
            const state = reactStore.getState()
            const { courseProgress } = state
            if (courseProgress) {
                localStorage.setItem(`lesson.progress.${id}`, JSON.stringify(courseProgress))
                localStorage.setItem(`lesson.progress.${id}.sum`, courseProgress.percentComplete)
            }

            if (this.props.onProgress) {
                this.props.onProgress(courseProgress.percentComplete)
            }
        })

        const storedProgress: any = localStorage.getItem(`lesson.progress.${id}`)

        if (storedProgress) {
            state.courseProgress = JSON.parse(storedProgress)
            // dispatch to render state changes.
            reactStore.dispatch({ type: 'none' })
        }
    }
}
