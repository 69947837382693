import React, { Component } from 'react'
import { Button, Col, ContainerProps, Navbar, Row } from 'react-bootstrap'
import CookieConsent from 'react-cookie-consent'
import { IconContext } from 'react-icons'
import { Link } from 'react-router-dom'

export interface BasicLayoutProps {
    title: string
    themeColor: string
    logo: {
        url: string
    }
    primaryLogoIconic?: {
        url: string
    }
    secondaryLogo?: {
        url: string
    }
    tertiaryLogo?: {
        url: string
    }
    hasNavbar?: boolean
    font?: {
        url: string
    }
    props?: {
        container?: Partial<ContainerProps>
    }
    cookieConcept: boolean
    onCookieConsentAccept?: () => void
    footer: boolean
    readable: boolean
    children?: React.ReactNode | React.ReactNode[]
}

export default class BasicLayout extends Component<BasicLayoutProps> {
    state = {}

    addNavbar() {
        const { logo, primaryLogoIconic, secondaryLogo, tertiaryLogo, title } = this.props
        return (
            <IconContext.Provider value={{ color: 'black' }}>
                <Navbar bg="light" style={{}}>
                    <Row className="navbar-row" noGutters>
                        {tertiaryLogo ? (
                            <Col className="tertiary-logo">
                                <a href="#/">
                                    <img alt="tertiary-logo" src={tertiaryLogo.url} />
                                </a>
                            </Col>
                        ) : (
                            <Col className="navbar-title">{title}</Col>
                        )}
                        <Col className="primary-logo" md="auto" xs="auto" sm="auto">
                            <img alt="primary-logo" src={logo.url} className="d-none d-md-block" />
                            {primaryLogoIconic ? (
                                <img
                                    alt="primary-logo-iconic"
                                    src={primaryLogoIconic.url}
                                    className="primary-logo-iconic d-block d-md-none"
                                />
                            ) : null}
                        </Col>
                        {secondaryLogo ? (
                            <Col className="secondary-logo d-none d-xl-block" md="auto">
                                <img alt="secondary-logo" src={secondaryLogo.url} />
                            </Col>
                        ) : null}
                    </Row>
                </Navbar>
            </IconContext.Provider>
        )
    }

    render() {
        const { readable, cookieConcept } = this.props

        return (
            <React.Fragment>
                {this.props.hasNavbar ? this.addNavbar() : null}
                <div
                    id="main-content-container"
                    className={'main-content-container'}
                    {...(this.props.props ? this.props.props.container || {} : {})}
                >
                    <div className={'section-container' + (readable ? '' : ' main-content-container-non-readable')}>
                        {this.props.children}
                    </div>
                    {this.props.footer ? (
                        <footer>
                            <Row className="footer-nav">
                                <Col md={2} xs={6} className="footer-links-col">
                                    <Link to="/dataprotection">Datenschutz</Link>
                                </Col>
                                <Col md={2} xs={6} className="footer-links-col">
                                    <Link to="/imprint">Impressum</Link>
                                </Col>
                            </Row>
                        </footer>
                    ) : null}
                </div>

                {cookieConcept ? (
                    <CookieConsent
                        declineButtonText="Nur funktionsfähig"
                        enableDeclineButton={true}
                        ButtonComponent={(props: any) => {
                            return (
                                <Button
                                    style={{ margin: 5 }}
                                    variant={props.id === 'decline' ? 'outline-warning' : 'success'}
                                    onClick={props.onClick}
                                >
                                    {props.children}
                                </Button>
                            )
                        }}
                        declineButtonId="decline"
                        buttonId="accept"
                        onAccept={this.onCookieConsentAccept}
                        buttonText="Alle Cookies"
                    >
                        Wir verwenden Cookies, um unsere Website und unseren Service zu optimieren.
                    </CookieConsent>
                ) : null}
            </React.Fragment>
        )
    }

    private onCookieConsentAccept = () => {
        if (this.props.onCookieConsentAccept) {
            this.props.onCookieConsentAccept()
        }
    }
}
