import ReactGA from 'react-ga4'

import React, { Component } from 'react'

import { getClientUrl } from '../helpers/url'
import BasicLayout, { BasicLayoutProps } from './BasicLayout'
import { trackPageView, isInitialized } from '../helpers/track'
import { GlobalStateContextType, GlobalStateContext } from '../helpers/GlobalState'
interface Props {
    onReceiveData: (data: ClientData) => void
    footer?: boolean
    hasNavbar?: boolean
    basicLayoutProps?: Partial<BasicLayoutProps>
    children?: React.ReactNode | React.ReactNode[]
}

export interface ClientLesson {
    header: string
    description: string
    id: string
    path: null
    visual: string
    is_bonus: boolean
}

export interface ClientData {
    lessons: ClientLesson[]
    title?: string
    subdomain: string
    logo: string
    primary_logo_iconic?: string
    secondary_logo?: string
    tertiary_logo?: string
    primary_color: string
    secondary_color: string
    font: string | null
    uses_voucher: boolean
    uses_shop: boolean
    terms: string | null
    header_video: string | null
    header_title: string
    header_title2: string
    header_title3: string
    header_title4: string
    header_title5: string
    header_title6: string
    header_image: string
    header_disabled: boolean
    cookie_consent_disabled: boolean
    footer_in_lessions: boolean
    intro_video: string | null
    intro_title: string
    analytics_id: string | null
    client_js: string
    theme_color: string
    intro_description: string | null
    vouchers: [{ code: string }]
}

interface State {
    data?: ClientData
}

export default class ClientLayout extends Component<Props, State> {
    state = { data: undefined } as State
    static contextType = GlobalStateContext

    async componentDidMount() {
        const response = await fetch(getClientUrl('/'))

        const data = (await response.json()) as ClientData
        if (data) {
            this.setState({ data }, this.injectJsCode)

            this.props.onReceiveData(data)
            if (data.analytics_id) {
                this.embedGoogleAnalytics(data.analytics_id)
            }
            window.addEventListener('popstate', () => {
                trackPageView()
            })
        } else {
            console.error('No backend page data loaded')
        }
    }

    render() {
        const { children } = this.props
        const { data } = this.state

        if (!data) {
            return 'Loading'
        }

        return (
            <BasicLayout
                footer={this.props.footer !== undefined ? this.props.footer : true}
                title={data.title || data.subdomain || ''}
                font={data.font ? { url: data.font } : undefined}
                logo={{ url: data.logo }}
                primaryLogoIconic={data.primary_logo_iconic ? { url: data.primary_logo_iconic } : undefined}
                secondaryLogo={data.secondary_logo ? { url: data.secondary_logo } : undefined}
                tertiaryLogo={data.tertiary_logo ? { url: data.tertiary_logo } : undefined}
                themeColor={data.theme_color}
                readable={true}
                cookieConcept={!data.cookie_consent_disabled}
                hasNavbar={this.props.hasNavbar !== undefined ? this.props.hasNavbar : true}
                onCookieConsentAccept={this.onCookieConsentAccept}
                {...(this.props.basicLayoutProps || {})}
            >
                {children}
            </BasicLayout>
        )
    }

    private onCookieConsentAccept = async () => {
        const { data } = this.state

        this.injectJsCode()

        if (!data || !data.analytics_id) return
        await this.embedGoogleAnalytics(data.analytics_id, true)
    }

    private embedGoogleAnalytics = async (id: string, force = false) => {
        if (document.cookie.indexOf('CookieConsent=true') === -1 && force === false) {
            return
        }

        ReactGA.initialize(id)

        const interval = setInterval(() => {
            if (isInitialized()) {
                clearInterval(interval)
                trackPageView()
            } else {
                // continue retry.
            }
        }, 500)
    }

    private injectJsCode = () => {
        const { data } = this.state
        if (window.location.hash === '#/imprint' || window.location.hash === '#/dataprotection') {
            // code injection in imprint and dataprotection is not allowed.
            return
        }

        if (document.cookie.indexOf('CookieConsent=true') === -1 && data?.cookie_consent_disabled === false) {
            // we don't inject anything if there is no cookie consent
            return
        }
        const { globalState, globalStateSetter } = this.context as GlobalStateContextType
        const code = this.state.data?.client_js
        if (globalState.jsInjectionDone || code === '' || code === undefined) {
            // Injection already happend before or there is nothing to inject -> skip
            return
        }
        if (globalStateSetter === undefined || globalStateSetter === null) {
            // Global State Setter is not ready yet - this should never happen and was only
            // added to make typescript happy
            return
        }
        const script = document.createElement('script')
        script.innerHTML = code
        document.body.appendChild(script)
        globalStateSetter({ jsInjectionDone: true })
    }
}
